import request from '@/utils/request';

// 学习计划 列表
export const idpList = params => request.post('study/idp/plan/list', params)

// 学习计划 详情
export const idpDetail = id => request.get(`study/idp/plan/${id}/detail`)

// 学习计划 增加 修改
export const idpSubmit = params => request.post('study/idp/plan/submit', params)

// 学习计划 作业 修改
export const idpHomeworkSubmit = params => request.post('study/idp/plan/homework/submit', params)

// 学习计划 删除
export const idpDelete = id => request.post(`study/idp/plan/${id}/del`)

// 学员列表
export const idpMemberList = params => request.post('study/idp/member/searchall', params)

// 试卷 目录
export const paperFolder = () => request.get('study/idp/plan/paper/folder/tree')

// 共享试卷 列表
export const idpPaperList = params => request.post('study/task/paper/share', params)

// 问卷 目录
export const questionnaireFolder = params => request.get('study/idp/plan/questionnaire/folder/tree')

// 全部问卷 列表
export const questionnaireList = params => request.post('study/idp/questionnaire/list', params)

// 共享问卷 列表
export const idpQuestionnaireList = params => request.post('study/task/questionnaire/share', params)

// 作业问卷 结果
export const idpQuestionnaireResult = params => request.get(`study/idp/questionnaire/${params.planId}/${params.planDetailId}/${params.questionnaireId}/${params.userId}/detail`)

// 作业问卷 提交
export const idpQuestionnaireSubmit = (ids, params) => request.post(`study/idp/questionnaire/${ids.planId}/${ids.planDetailId}/${ids.questionnaireId}/${ids.userId}/submit`, params)